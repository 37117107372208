import peek from 'img/buttons/2.svg'
import more from 'img/buttons/3.svg'

const hoverMap = {
  0: peek,
  1: more
}

export function getHoverImg () {
  const i = Math.floor(Math.random() * 2)
  return hoverMap[i]
}
  