import React, { useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { Link, Container } from 'components/Core'
import { getHoverImg } from 'services/hover-image'

const Block = styled(Link)`
  position: relative;
  padding: 5px;
  max-width: 360px;
  max-height: 360px;
  flex: 1 1 300px;
`

const ImageTextContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 2rem;
  display: flex;
`

const ImageText = styled.div`
  margin: 0 auto;
  padding-right: 10px;
  text-align: center;
  width: 90%;
  font-family: FVAlmelo;
  font-size: 35px;
  font-weight: 100;
  text-transform: none;
  color: #FFFFFF;
  line-height: 32px;
  letter-spacing: 0;
`

const HoverImg = styled.img`
  position: absolute;
  bottom: 30%;
  left: 30%;
`

export default function PortfolioListItem ({ node, img }) {
  const [active, setActive] = useState(false)
  
  const mouseHandler = (newVal) => (e) => {
    setActive(newVal)
  }

  const renderOverlay = () => {
    if (!active) {
      return (
        <ImageTextContainer>
          <ImageText>{node.title}</ImageText>
        </ImageTextContainer>
      )
    }
    return (
      <HoverImg src={getHoverImg()} alt="hover"/>
    )
  }

  return (
    <Block to={`/portfolio/${node.name}`}>
      <Container
        role="none"
        onMouseEnter={mouseHandler(true)}
        onMouseLeave={mouseHandler(false)}
      >
        <Img
          fluid={img.childImageSharp.fluid}
          alt={img.relativePath}
        />
        { renderOverlay() }
      </Container>
    </Block>
  )
}
