import React from "react"
import styled from "styled-components"
import PortfolioListItem from 'components/portfolio/PortfolioListItem'

const Grid = styled.div`
  width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default function PortfolioList ({ jsonNodes, imgNodes }) {
  const renderItem = (node) => {
    const f = imgNodes.find(i => i.relativePath === `img/portfolio/${node.name}/main.png`)
    return <PortfolioListItem key={node.name} node={node} img={f} />
  }
  
  return (
    <Grid>
      { jsonNodes.map(n => renderItem(n)) }
    </Grid>
  )
}
