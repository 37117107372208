import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import fb from 'img/icon/facebook.svg'
import ig from 'img/icon/instagram.svg'
import li from 'img/icon/linkedin.svg'
import { pallete } from 'services/theme'
import { FlexBox, TextContainer, Typography, CenterText, Spacer, Anchor } from 'components/Core'

const SocialLink = styled(Anchor)`
  // outline: none;
  padding: 1rem;
`

const EmailLink = styled(Anchor)`
  color: black;
  // text-decoration: none;
  &:hover {
    color: ${pallete.pink.main};
  }
`

export default function Bio ({ bioJson, imgNodes, jsonNodes }) {
  const renderImgNodes = () => {
    return jsonNodes.map(node => {
      const f = imgNodes.find(i => i.relativePath === `img/happy-clients/${node.name}`)
      return (
        <Img
          key={f.relativePath}
          fixed={f.childImageSharp.fixed}
          alt={f.relativePath}
        />
      )
    })
  }

  return (
    <FlexBox>
      <TextContainer>
        <Typography variant="title2">ABOUT ME</Typography>
        <Spacer size="1" />
        <Typography variant="body1" font="secondary">{bioJson.bio}</Typography>
        <Spacer size="1" />
        <Typography variant="body1" font="secondary">
        {`Design Inquires | `}
          <EmailLink
            href={`mailto:${bioJson.email}`}
          >
            {bioJson.email}
          </EmailLink>
        </Typography>
        <Spacer size="1" />
        <Typography variant="body1" font="secondary">{bioJson.skills}</Typography>
        <Spacer size="2" />
        <Typography variant="title2">MORE HAPPY CLIENTS</Typography>
        <Spacer size="1" />
        <CenterText>
          { renderImgNodes() }
        </CenterText>
        <Spacer size="2" />
        <CenterText variant="2">
          <SocialLink href={bioJson.facebook} target="_blank" rel="noopener noreferrer">
            <img src={fb} alt="fb" />
          </SocialLink>
          <SocialLink href={bioJson.linkedin} target="_blank" rel="noopener noreferrer">
            <img src={li} alt="li" />
          </SocialLink>
          <SocialLink href={bioJson.instagram} target="_blank" rel="noopener noreferrer">
            <img src={ig} alt="ig" />
          </SocialLink>
        </CenterText>
        <Spacer size="2" />
      </TextContainer>
    </FlexBox>
  )
}
