import React from "react"
import { graphql } from 'gatsby'
import SEO from 'components/SEO'
import CssBaseline from 'components/CssBaseline'
import Header from 'components/Header'
import PortfolioList from 'components/portfolio/PortfolioList'
import PortfolioPicture from 'components/portfolio/PortfolioPicture'
import Bio from 'components/portfolio/Bio'

export default function Portfolio({ data }) {
  return (
    <CssBaseline grid>
      <SEO title="PORTFOLIO" />
      <div className="container">
        <Header backTo="/" />
        <PortfolioList
          jsonNodes={data.allPortfolioJson.nodes} 
          imgNodes={data.portfolioImg.nodes}
        />
      </div>

      <PortfolioPicture bioImg={data.bioImg} />
      
      <div className="container">
        <Bio
          bioJson={data.bioJson} 
          imgNodes={data.happyClientsImg.nodes} 
          jsonNodes={data.allHappyClientsJson.nodes} 
        />
      </div>
    </CssBaseline>
  )
}

export const query = graphql`
  query {
    bioJson {
      bio
      email
      facebook
      instagram
      linkedin
      skills
    }
    allHappyClientsJson {
      nodes {
        name
      }
    }
    allPortfolioJson(sort: {fields: index, order: ASC}) {
      nodes {
        index
        name
        title
      }
    }
    bioImg: file(relativePath: {eq: "img/bio/main.png"}) {
      id
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    portfolioImg: allFile(filter: {relativePath: {glob: "img/portfolio/**/main.png"}}, sort: {fields: relativePath}) {
      nodes {
        relativePath
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    happyClientsImg: allFile(filter: {relativePath: {glob: "img/happy-clients/*.*"}}, sort: {fields: relativePath}) {
      nodes {
        relativePath
        childImageSharp {
          fixed(width: 144) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
