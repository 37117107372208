import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Lottie from 'react-lottie'
import animationData from 'img/animation.json'

const height = '30rem'
const width = '100%'

const options = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
  animationData,
}

const style = {
  position: 'relative',
  margin: 0,
  padding: 0
}

const Container = styled.div`
  width: 100%;
  height: ${height};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`

const ImageContainer = styled.div`
  height: 200px;
  width: 200px;
  position: absolute;
  z-index: 200;
`

export default function PortfolioPicture ({ bioImg }) {
  return (
    <Container>
      <ImageContainer>
        <Img
          fluid={bioImg.childImageSharp.fluid}
          alt={bioImg.relativePath}
        />
      </ImageContainer>
      <Lottie
        style={style}
        options={options}
        height={height}
        width={width}
      />
    </Container>
  )
}
